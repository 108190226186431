import React from 'react'
import "../../css/viewdebugger.css"
import Visualizer from '../component/Visualizer';

export default function ViewDebugger() {

  return (
    <div className='vd-div-main'>
      <Visualizer />
    </div>
  )
}
